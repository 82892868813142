
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Field from "@/components/Field.vue";
import LangSelect from "@/components/LangSelect.vue";
import Logo from "@/components/Logo.vue";
import validator from "validator";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";
import "../assets/images/logo.svg";
import { RoutesNames } from "../router";
import { apolloClient } from "../vue-apollo";

@Component({
  components: {
    Field,
    Card,
    Btn,
    LangSelect,
    Logo,
  },
})
export default class ForgotPasswordView extends Vue {
  email = "";
  loading = false;
  isFormValid = false;

  @Action forgotPassword;
  @Mutation showError;
  @Mutation showSuccess;

  mounted() {
    this.$store.commit("setFillHeightOnMainContainer", true);
    this.resetValues();
  }

  resetValues() {
    (this.$refs.forgotPasswordForm as Vue & { reset: () => void })?.reset();
  }

  emailRules = [
    (v) => !!v || "Inserire la mail",
    (v) => !!validator.isEmail(v ? v : "") || "Email non valida",
  ];

  async sendForgotPasswordRequest() {
    if (
      (this.$refs.forgotPasswordForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      this.loading = true;
      await this.forgotPassword({ email: this.email });
      this.loading = false;
    }
  }

  async getUser() {
    const userRes = await apolloClient.query({
      query: require("@/graphql/getUserByGuid.graphql"),
      variables: {
        email: this.email,
      },
      fetchPolicy: "no-cache",
    });
    return userRes.data.User;
  }

  getCopy() {
    return (
      "© " +
      new Date().getFullYear() +
      " CNA™ - " +
      this.$t("allRightsReserved")
    );
  }

  goToLogin() {
    this.$router.push({ name: RoutesNames.login });
  }

  goToSetPassword() {
    this.$router.push({
      name: RoutesNames.setPassword,
      params: { type: "code" },
    });
  }
}
